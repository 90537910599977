import { defineStore } from "pinia";
import moment from "moment";

export const useGeneralStore = defineStore("general", {
  state: () => {
    return {
      isDeviceReady: false,
      online: false,
      currentLat: null,
      currentLon: null,
    };
  },
  actions: {
    setDeviceReady() {
      this.$patch({
        isDeviceReady: true,
      });
    },
    setOnline() {
      this.$patch({
        online: true,
      });
    },
    setOffline() {
      this.$patch({
        online: false,
      });
    },
    toggle() {
      const current = this.online;

      this.$patch({
        online: !current,
      });
    },
    updateCurrentPosition(lat, lon) {
      this.$patch({
        currentLat: lat,
        currentLon: lon,
      });
    },
  },

  getters: {
    currentDateTime() {
      return moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ");
    },
  },
});
