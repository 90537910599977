<template>
  <div id="pod-popup-container">
    <DxPopup
      :title="popUpTitle"
      :visible="true"
      :dragEnabled="false"
      :show-title="true"
      :close-on-outside-click="false"
      :show-close-button="true"
      width="90%"
      height="600"
      @hidden="hidden"
    >
      <DxScrollView
        ref="scrollView"
        height="100%"
        direction="vertical"
        :disabled="isScrollViewDisabled"
        :use-native="false"
      >
        <div style="display: flex; flex-direction: column">
          <div>
            <DxList
              :pullRefreshEnabled="false"
              height="auto"
              :selectByClick="true"
              selectionMode="multiple"
              :focusStateEnabled="false"
              :hoverStateEnabled="false"
              :activeStateEnabled="false"
              :bounceEnabled="false"
              :collapsibleGroups="true"
              :grouped="true"
              :scrollingEnabled="false"
              :scrollByThumb="false"
              :scrollByContent="false"
              :useNativeScrolling="false"
              :data-source="receiverItemsDataSource"
              @contentReady="handleReceiverItemsContentReady"
            >
              <template #group="">
                <div>Scanned Items:</div>
              </template>

              <template #item="{ data: item, index }">
                <div>
                  <span>{{ index + 1 }}</span
                  >)<span> {{ item.SSCC }}</span> -
                  <span
                    ><strong
                      >({{ getScannedQty(item) }}/{{
                        item.Original_Qty
                      }})</strong
                    ></span
                  >
                </div>
              </template>
            </DxList>
          </div>
          <canvas
            id="signature-pad"
            ref="signaturePad"
            class="sign-canvas"
            width="300px"
            height="300px"
            style="margin-top: 2em"
          />
          <div style="margin-top: 0.5em; align-self: flex-end">
            <DxButton
              icon="trash"
              type="danger"
              @click="handleClearSignature"
            />
            <DxButton
              icon="revert"
              type="default"
              @click="handleUndoSignature"
            />
          </div>
          <DxTextArea
            :placeholder="$t('common.hint_comment')"
            max-length="100"
            v-model="commentValue"
            style="margin-top: 2em"
          />
          <ImagePickerButton
            ref="btn-take-photo"
            width="100%"
            icon="photo"
            :buttonText="$t('common.btn_take_picture')"
            :disabled="false"
            :imageResult.sync="photoResult"
            @uploaded="handlePhotoUploaded"
            style="margin-top: 1em"
          />
          <DxButton
            class="button-save-pod"
            width="100%"
            :disabled="!isValid || isLoading"
            @click="handleSaveSignature"
            type="success"
            :text="$t('common.btn_save')"
            icon="check"
            style="margin-top: 1em"
          />
        </div>
      </DxScrollView>
    </DxPopup>
    <DxLoadPanel
      :visible="isLoading"
      message="Please wait..."
      :position="{ of: '#content-container' }"
      :close-on-outside-click="false"
    />
  </div>
</template>
<script>
import api from "../../commons/api";
import service from "../../commons/service";
import util from "../../commons/utility";
import { useGeneralStore } from "../../stores/generalStore";
import i18n from "../../i18n";
import { truckMode } from "../../commons/constants";
import DxPopup from "devextreme-vue/popup";
import SignaturePad from "signature_pad";
import DxButton from "devextreme-vue/button";
import DxList from "devextreme-vue/list";
import DxScrollView from "devextreme-vue/scroll-view";
import DataSource from "devextreme/data/data_source";
import DxTextArea from "devextreme-vue/text-area";
import ImagePickerButton from "../../components/image-picker-button";
import DxLoadPanel from "devextreme-vue/load-panel";

export default {
  props: {
    tasks: {
      type: Array,
      default: () => [],
    },
    visible: {
      type: Boolean,
      default: false,
    },
    truckMode: {
      type: String,
      default: truckMode.LOAD,
    },
    hidden: Function,
  },
  data() {
    return {
      signaturePad: null,
      isScrollViewDisabled: false,
      receiverItemsDataSource: new DataSource({
        key: "Seq",
        paginate: false,
        onChanged: this.handlePlansDataChange,
        load: () => {
          const childTaskIds = this.tasks.map((element) => element.GuidTaskID);

          const promise = service.getTaskLinesByTaskIds(childTaskIds, true);

          promise.then((response) => {
            const groupedItems = [];
            const receivertItems = [...(response.data ?? [])];

            groupedItems.push({ items: receivertItems });
            response.data = groupedItems;
          });

          return promise;
        },
      }),

      photoResult: null,
      commentValue: "",
      isLoading: false,
    };
  },
  mounted() {
    const canvas = this.$refs["signaturePad"];

    this.signaturePad = new SignaturePad(canvas, {
      backgroundColor: "lightgray",
      penColor: "blue",
    });

    this.signaturePad.addEventListener(
      "beginStroke",
      () => {
        if (!this.isScrollViewDisabled) this.isScrollViewDisabled = true;
      },
      { once: false }
    );

    this.signaturePad.addEventListener(
      "endStroke",
      () => {
        if (this.isScrollViewDisabled) this.isScrollViewDisabled = false;
      },
      { once: false }
    );
  },
  computed: {
    popUpTitle() {
      const tasks = this.tasks;
      if (tasks == null || tasks.length <= 0) return "";
      return tasks[0].Sites.Description;
    },
    siteID() {
      const passedTasks = this.tasks;
      if (passedTasks == null || passedTasks == 0) return "";

      return passedTasks[0].SiteID;
    },
    isCameraReady() {
      return (
        navigator !== undefined &&
        navigator !== null &&
        navigator.camera !== undefined &&
        navigator.camera !== null
      );
    },
    receiverItems() {
      try {
        return this.receiverItemsDataSource.items()[0].items;
      } catch (e) {
        return [];
      }
    },
    isValid() {
      if (this.signaturePad == null || this.signaturePad.isEmpty())
        return false;

      if (this.isCameraReady && this.photoResult == null) {
        return false;
      }
      return true;
    },
  },
  methods: {
    handleUndoSignature() {
      let data = this.signaturePad.toData();
      if (data) {
        data.pop();
        this.signaturePad.fromData(data);
      }
    },
    handleClearSignature() {
      this.signaturePad.clear();
    },

    handleCancelSignature() {
      this.handleClearSignature();
    },
    async handleSaveSignature() {
      const actionName = "Task Completed";

      // TODO Raymon
      const store = useGeneralStore();
      const isOnline = store.online;

      const currentLat = store.currentLat;
      const currentLon = store.currentLon;

      // Keep for debugging purpose
      // alert(JSON.stringify({ currentLat, currentLon }));
      console.log("currentLatLon", { currentLat, currentLon });

      if (!isOnline) {
        this.isLoading = true;
        setTimeout(() => {
          this.tasks.forEach((task) => {
            this.saveTaskDataInLocal(task, this.receiverItems);
          });

          this.$root.showSuccess(this.getSuccessMessage(actionName), 2000);
          this.$emit("tasksCompleted");
          this.resetState();
          this.isLoading = false;
        }, 3000);

        return;
      }

      // Value1 =
      // Value2 =
      // Value3 = comment
      // Value4 =
      // Value5 = photo
      // Value6 = odometer
      // Value7 = signature

      const siteID = this.siteID;

      const taskIds = [
        ...new Set(this.receiverItems.map((e) => e.TaskIDString)),
      ];

      if (siteID == null || taskIds == null || taskIds.length <= 0) {
        return;
      }

      const requests = [];
      for (const taskId of taskIds) {
        const param = {
          ResourceID: util.getSelectedResourceId(),
          AllocationID: util.getSelectedAllocationId(),
          SiteID: siteID,
          LogType: actionName,
          Gps_Time: util.getCurrentDateStr(),
          Value3: this.commentValue,
          Latitude: currentLat,
          Longitude: currentLon,
          TaskID: taskId,
        };

        const promise = this.getLogRequest(api.POST_LOG_URL, param);
        requests.push(promise);
      }

      this.isLoading = true;

      let logIds = await Promise.all(requests);
      logIds = logIds.filter((logId) => logId != null);

      if (logIds == null || logIds.length <= 0) {
        // error
        this.isLoading = false;
        this.$root.showError("Error", 2000);
        return;
      }

      this.tasks.forEach((task) => {
        this.saveTaskDataInLocal(task, this.receiverItems);
      });

      if (this.photoResult) {
        try {
          await this.$http({
            method: "post",
            url: api.POST_RESOURCE_LOGS_IMAGE_URL,
            params: {
              LogID: logIds.join(","),
              Type: "Value5",
            },
            data: Buffer.from(this.photoResult.split(";base64,")[1], "base64"),
            headers: { "Content-Type": "image/jpeg" },
          });
        } catch (e) {
          // IGNORED
        }
      }

      if (this.signaturePad.isEmpty()) {
        this.isLoading = false;
        this.$root.showSuccess(
          this.getSuccessMessage(actionName, logIds.length),
          2000
        );
        this.$emit("tasksCompleted");
        this.resetState();
        return;
      }

      const signatureImage = this.signaturePad.toDataURL("image/jpeg");

      this.$http({
        method: "post",
        url: api.POST_RESOURCE_LOGS_IMAGE_URL,
        params: {
          LogID: logIds.join(","),
          Type: "Value7",
        },
        data: Buffer.from(signatureImage.split(";base64,")[1], "base64"),
        headers: { "Content-Type": "image/jpeg" },
      })
        .then((resp) => {
          this.$root.showSuccess(
            this.getSuccessMessage(actionName, logIds.length),
            2000
          );
          this.$emit("tasksCompleted");
          this.resetState();
          this.isLoading = false;
        })
        .catch((err) => {
          this.$root.showSuccess(
            this.getSuccessMessage(actionName, logIds.length),
            2000
          );
          this.$emit("tasksCompleted");
          this.resetState();
          this.isLoading = false;
        });
    },
    handleReceiverItemsContentReady() {},
    getScannedQty(item) {
      if (this.truckMode == truckMode.LOAD) {
        return item.Load_Qty;
      } else if (this.truckMode == truckMode.UNLOAD) {
        return item.Unload_QtY;
      }

      return 0;
    },
    handlePhotoUploaded(data) {},
    saveTaskDataInLocal(task, receiverItems) {
      const currentreceiverItems = receiverItems.filter(
        (taskItem) => taskItem.TaskID === task.GuidTaskID
      );

      let isAnyItemSkipped = false;
      currentreceiverItems.forEach((currentTaskItem) => {
        const qty = currentTaskItem.Original_Qty;
        const scannedQty = this.getScannedQty(currentTaskItem);

        if (scannedQty < qty) {
          // skip this task
          util.saveSkippedTask(task.Seq);
          isAnyItemSkipped = true;
        }
      });

      if (!isAnyItemSkipped) {
        util.saveCompletedTask(task.Seq);
      }
    },
    resetState() {
      this.signaturePad.clear();
      this.photoResult = null;
      this.commentValue = "";
    },
    getLogRequest(url, param) {
      const promise = new Promise((resolve, _) => {
        this.$http
          .post(url, param)
          .then((response) => {
            const logId = response.data.LogID;
            resolve(logId);
          })
          .catch((_) => resolve(null));
      });

      return promise;
    },
    getSuccessMessage(actionName, logCount = 0) {
      const translatedActionName = i18n.t(
        `dictionaries.${actionName.replace(" ", "_")}`
      );

      if (logCount > 0) {
        return `${i18n.t("pod.msg_success", {
          actionName: translatedActionName,
          logCount: logCount,
        })}`;
      } else {
        return `${i18n.t("pod.msg_success_empty", {
          actionName: translatedActionName,
        })}`;
      }
    },
  },
  components: {
    DxPopup,
    DxList,
    DxButton,
    DxScrollView,
    ImagePickerButton,
    DxTextArea,
    DxLoadPanel,
  },
};
</script>
<style lang="scss" scoped>
.button-save-pod .dx-button-icon {
  height: 20px;
  width: 20px;
  vertical-align: middle;
  margin-right: 5px;
}
.button-save-pod .button-indicator {
  height: 20px;
  width: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
</style>
