// import { pinia } from "../main";
import { useGeneralStore } from "../stores/generalStore";
import remoteRepository from "./remote-repository";
import localRepository from "./local-repository";
import utility from "./utility";

let store = null;

function initialize() {
  console.log("initialize");
  store = useGeneralStore();
}

function getAllocationsByUserName(userName, rawResponse = false) {
  const isOnline = store.online;
  if (isOnline) {
    return remoteRepository.getAllocationsByUserName(userName, rawResponse);
  } else {
    return localRepository.getAllocationsByUserName(userName, rawResponse);
  }
}

function getActivityTypes(rawResponse = false) {
  const isOnline = store.online;
  if (isOnline) {
    return remoteRepository.getActivityTypes(rawResponse);
  } else {
    return localRepository.getActivityTypes(rawResponse);
  }
}

function getAllocationPlansByAllocationId(allocationId, rawResponse = false) {
  const isOnline = store.online;
  if (isOnline) {
    return remoteRepository.getAllocationPlansByAllocationId(
      allocationId,
      rawResponse
    );
  } else {
    return localRepository.getAllocationPlansByAllocationId(
      allocationId,
      rawResponse
    );
  }
}

function getTasksByAllocationId(allocationId, rawResponse = false) {
  const isOnline = store.online;
  if (isOnline) {
    return remoteRepository.getTasksByAllocationId(allocationId, rawResponse);
  } else {
    return localRepository.getTasksByAllocationId(allocationId, rawResponse);
  }
}

function getTaskLinesByTaskIds(taskIds, rawResponse = false) {
  const isOnline = store.online;
  if (isOnline) {
    return remoteRepository.getTaskLinesByTaskIds(taskIds, rawResponse);
  } else {
    return localRepository.getTaskLinesByTaskIds(taskIds, rawResponse);
  }
}

function postTaskLine(taskLine, rawResponse = false) {
  const isOnline = store.online;
  if (isOnline) {
    localRepository.postTaskLine(taskLine, rawResponse);
    return remoteRepository.postTaskLine(taskLine, rawResponse);
  } else {
    return localRepository.postTaskLine(taskLine, rawResponse);
  }
}

function validateTaskLines(taskLines, rawResponse = false) {
  const isOnline = store.online;
  if (isOnline) {
    localRepository.postTaskLine(taskLines, rawResponse);
    return remoteRepository.validateTaskLines(taskLines, rawResponse);
  } else {
    return localRepository.validateTaskLines(taskLines, rawResponse);
  }
}

function getResourceLogsByAllocationId(allocationId, rawResponse = false) {
  const isOnline = store.online;
  if (isOnline) {
    return remoteRepository.getResourceLogsByAllocationId(
      allocationId,
      rawResponse
    );
  } else {
    return localRepository.getResourceLogsByAllocationId(
      allocationId,
      rawResponse
    );
  }
}

async function downloadResourcesForOfflineMode(allocationId) {
  try {
    // alert("allocationId = " + JSON.stringify(allocationId));

    // get activity types
    const activityTypes = await remoteRepository.getActivityTypes();
    // alert("activityTypes = " + JSON.stringify(activityTypes));
    localRepository.saveActivityTypes(activityTypes);

    // get tasks
    const tasks = await remoteRepository.getTasksByAllocationId(allocationId);
    // alert("tasks = " + JSON.stringify(tasks));
    localRepository.saveTasksAllocation(tasks);

    // get tasks lines
    const taskIds = tasks.map((task) => task["GuidTaskID"]);
    // alert("taskIds = " + JSON.stringify(taskIds));

    const taskLines = await remoteRepository.getTaskLinesByTaskIds(taskIds);
    // alert("taskLines = " + JSON.stringify(taskLines));
    localRepository.saveTaskLines(taskLines);

    //get resource logs
    const resourceLogs = await remoteRepository.getResourceLogsByAllocationId(
      allocationId
    );
    // alert("resourceLogs = " + JSON.stringify(resourceLogs));
    localRepository.saveResourceLogs(resourceLogs);

    return true;
  } catch (e) {
    //
    // alert(JSON.stringify(e));
    return false;
  }
}

async function deleteLocalData() {
  utility.deleteAllData();
  return localRepository.truncateTables();
}

async function deleteOfflineData() {
  return localRepository.truncateTables();
}

function setPositionTrackerEnabled(isEnabled) {
  utility.setTrackerEnabled(isEnabled);
}

function isPositionTrackerEnabled() {
  return utility.isTrackerEnabled();
}

async function updateCurrentPosition(lat, lon) {
  store.updateCurrentPosition(lat, lon);
}

async function getCurrentPosition() {
  const currentLat = store.currentLat;
  const currentLon = store.currentLon;

  return {
    lat: currentLat,
    lon: currentLon,
  };
}

export default {
  initialize,
  deleteLocalData,
  deleteOfflineData,
  getAllocationsByUserName,
  getActivityTypes,
  getAllocationPlansByAllocationId,
  getTasksByAllocationId,
  getTaskLinesByTaskIds,
  postTaskLine,
  validateTaskLines,
  getResourceLogsByAllocationId,
  downloadResourcesForOfflineMode,
  updateCurrentPosition,
  getCurrentPosition,
  setPositionTrackerEnabled,
  isPositionTrackerEnabled,
};
